$color-title: #721c24;
$color-navbar: #006FBD;

$primary: #007bff;

$verde: #8ec640;
$verde-nuevo: #006f43;
$verde-primario: #0c3529;
$verde-secundario: #035b2f;
$azul-secundario: #0c3856;
$azul: #215273;
$rojo: #9b1c22;
$cian: #55c4d4;
$gris: #454545;
$gris-medium: #6e6e6e;
/*Gris claro*/
$gris-claro: #ebebeb;
$negro: #000;

:root {
    --color-pendiente: #4d7c0f;
    --color-revision: #15803d;
    --color-preparacion: #047857;
    --color-progreso: #0f766e;
    --color-validacion: #0e7490;
    --color-finalizado: #1d4ed8;
}
  
  /* Estilo de la barra de pestañas */
.tab-bar {
    background-color: lightgray;
}
  
/* Estilo de los diferentes colores de las pestañas */
.tab-pendiente {
    background-color: var(--color-pendiente);
}
  
.tab-revision {
    background-color: var(--color-revision);
}
  
.tab-preparacion {
    background-color: var(--color-preparacion);
}
  
.tab-progreso {
    background-color: var(--color-progreso);
}
  
.tab-validacion {
    background-color: var(--color-validacion);
}

.tab-finalizado {
  background-color: var(--color-finalizado);
}

/* colores para los tabs */




.verde{
    color: $verde;
}
.verde-nuevo{
    color: $verde-nuevo;
}
.verde-primario {
    color: $verde-primario;
}
.verde-secundario {
    color: $verde-secundario;
}
.azul-secundario {
    color: $azul-secundario;
}
.azul{
    color: $azul;
}
.rojo{
    color: $rojo;
}
.cian{
    color: $cian;
}
.gris{
    color: $gris;
}
.gris-claro{
    color: $gris-claro;
}
.negro{
    color: $negro;
}
