.react-date-picker {
  width: 100%;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-datepicker-wrapper {
  /* border-radius: 1rem;
    height: 32px;
    border: 1px solid #ced4da;
    width: 100%; */
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: $verde-secundario !important;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 275px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar {
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  border-radius: 1.5rem;
  font-family: inherit;
  line-height: 1.125em;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 5rem;
}

.react-calendar__tile--active {
  background: $verde-secundario !important;
  color: white;
  border-radius: 5rem;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  border-radius: 5rem;
}


/* CSS PARA EL NUEVO DATE PICKER WITH MOMENT */
.react-datepicker {
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font: inherit;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 1.25rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__input-container {
  justify-content: center;
  font: inherit;
  padding: 0 2px;
  width: 100%;
}

.react-datepicker__input-container input {
  text-align: center;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 2rem;
  padding: .375rem .75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  /* color: $azul !important; */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.25rem;
  -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.react-datepicker__input-container input:focus {
  border-color: $verde-secundario;
  outline: 0;
  box-shadow: unset !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 1.25rem;
  background-color: $verde-secundario;
  color: #fff;
}

.react-datepicker__day--selected:hover {
  border-radius: 1.25rem;
  background-color: $verde-secundario !important;
}

.react-time-picker__inputGroup {
  text-align: center;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  padding-top: 8px;
  position: relative;
  font: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 1.25rem;
  background-color: $verde-secundario !important;
  color: #fff;
}

/* Boton hoy de react date picker */
.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 1px 0;
  clear: left;
  border-radius: 0 0 1.25rem 1.25rem;
}

/*
.react-datepicker__day:hover {
    border-radius: 1.25rem;
    background-color: #035192;
    color: #fff;
}
 */