/* MyTabs.css */

/* Establece un ancho específico para las pestañas */
.react-tabs__tab {
    width: 100px;
    /* Ajusta el ancho según tus necesidades */
}

/* Establece un ancho específico para los paneles de contenido */
.react-tabs__tab-panel {
    width: 200px;
    /* Ajusta el ancho según tus necesidades */
}

/* ----- */
.react-tabs__tab-panel_background {
    background: #000 !important;
}