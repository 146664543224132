.form-control {
    display: block;
    width: 100%;
    height: 2rem;
    padding: .375rem .75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    /* color: $azul !important; */
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 1.25rem;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control-disabled {
    display: block;
    width: 100%;
    height: 2rem;
    padding: .375rem .75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    /* color: $azul !important; */
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-radius: 0;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}





.form-control-inline {
    height: 2rem;
    padding: .375rem .75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    /* color: #035192 !important; */
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 1.25rem;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: initial;
    border: 0
}

.form-control:focus {
    border-color: #0c3529;
    outline: 0;
    box-shadow: unset !important;
    background-color: transparent;
}

/*BUSCADOR*/

.container-search {
  /*position: relative;*/
  /*  @media (max-width: 425px) {*/
  /*  margin-top: 5px;*/
  /*}*/
    position: relative;
    width: 220px;
}
.input--search {
    width: 200px;
    max-width:0;
    padding: 10px;
    transition: all .5s ease;
    position:absolute;
    right:5px;
    box-sizing:border-box;
    opacity:0;
    border-top: 1px solid #0c3529;
    border-left: 1px solid #0c3529;
    border-bottom: 1px solid #0c3529;
    font-weight: bold;
  /*  width: 100%;*/
  /*  transition: width 0.3s;*/
  /*  padding-left: 1rem;*/
  /*  padding-right: 1.5rem;*/
  /*  border-top-color: $cian;*/
  /*  border-left-color: $cian;*/
  /*  border-bottom-color: $azul;*/
  /*  border-right-color: $azul;*/
  /*  background-image: url('../assets/img/icons/buscar.png');*/
  /*  background-repeat: no-repeat;*/
  /*  background-position: center right calc(0.375em + 0.1875rem);*/
  /*  background-size: 1.7rem;*/
  /*@media only screen and (min-width: 992px) {*/
  /*  width: 25px;*/
  /*}*/
}
.input--search.expanded {
    max-width:500px;
    opacity:1;
}
.search_active {
    background-color: #0c3529 !important;
    color: white !important;
}
.icon-search {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    border-radius: 1rem;
    background: white;
    cursor: pointer;
}


/*CAMPO INVALIDO*/
.invalid-feedback{
    color: $rojo;
}
.is-invalid{
    border-color: $rojo !important;
}


/*SELECTS*/
.css-bg1rzq-control{
    border-radius: 1rem !important;
    font-size: 12px !important;
    min-height: unset !important;
    .css-151xaom-placeholder{
        font-size: 14px;
    }
}

/*Icono de expandible*/
.css-16pqwjk-indicatorContainer {
    /* color: #035192 !important; */
    padding: 0 8px 0 2px !important;
}
.css-1thkkgx-indicatorContainer {
    /* color: #035192 !important; */
    padding: 0 8px 0 2px !important;
}
/*Icono separador*/
.css-bgvzuu-indicatorSeparator{
    background-color: white !important;
}
/*Selecto cuando esta activo*/
.css-1szy77t-control{
    border-radius: 1rem !important;
    font-size: 12px !important;
    min-height: unset !important;
}

/*Selecto cuando esta desactivado*/
.css-14ojxsb-control{
    border-radius: 1rem !important;
    border: 1px solid #ced4da !important;
    font-size: 12px !important;
    min-height: unset !important;
    background-color: #e9ecef !important;
}
/*El texto de adentro*/
.css-dvua67-singleValue{
    font-size: 12px !important;
    /* color: $azul !important; */
}
.css-ue83k8-singleValue{
    font-size: 12px !important;
    /* color: $azul !important; */
}
/*Items del select*/
.css-kj6f9i-menu{
    font-size: 12px !important;
}

.invalid-force{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $rojo;
}

.react-time-picker{
    width: 100%;
}
/*TIME PICKER*/
.react-time-picker__wrapper{
    border-radius: 1rem;
    height: 30px;
    border: 1px solid #ced4da;
    width: 100%;
}
.react-time-picker__inputGroup{
    justify-content: center;
}

.tags-container{
    border: 1px solid #ced4da;
    border-radius: 1rem;
    overflow: hidden;
}
/*TAGS INPUT VALUES*/
.react-tagsinput-tag {
    background-color: #ffffff;
    border-radius: 1rem;
    border: 1px solid #ced4da;
    color: $azul;
    display: inline-block;
    font-family: unset !important;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0 !important;
    margin-right: 5px;
    padding: 4px;
}
.react-tagsinput-tag a::before {
    content: " \D7";
}
